import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import {SectionHero, Section, SectionDescription, SectionPageTitle, SectionImageBkg} from "../components/Section"
import SearchBar from "../components/SearchBar"
import ContactForm from "../components/ContactForm"
import PosterCircle from "../images/poster-circle.svg"

const SectionHeros = styled(SectionHero)`
	position:relative;
    height: 100%;
`
const HeroBanner = styled.div`	
	position: relative;
    .gatsby-image-wrapper {
        width:100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }
`
const HeroCaption = styled.div`	
    position:relative;
    z-index:1;
    padding:40px 0 0;
    @media (min-width:992px){
        padding:0 0 60px;
    }
    @media (min-width:1200px){
        padding:0 0 120px;
    }
    @media (min-width:1600px){
        padding:0 0 120px;
    }
`
const HeroSearch = styled.div`
    @media (min-width:992px){
        min-height: 78px;
        margin-top:-78px;
        position: sticky;
        bottom:0;
        left:0;
        z-index: 1;    
    }
    @media (min-width:1600px){
        min-height:100px;
        margin-top:-100px;
    }
    .search-bar{
        @media (min-width:992px){
            border-radius: 15px 15px 0 0;
        }
        @media(min-width:1200px){
            border-radius: 20px 20px 0 0;
        }
    }
    .container{
        @media (max-width:991.98px){
            padding:0;
        }
    }
`

const HeroCaptionGrid = styled.div`
	display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin:0 -15px;
    @media (min-width:1200px){
        margin:0 -30px;
    }
`
const HeroCaptionLeft = styled.div`
    position:relative;
    width:100%;
    @media (min-width:992px){
        max-width:50%;
        flex:0 0 50%;
    }
    align-items: center;
    display: flex;

    padding:0 15px;
    @media (min-width:1200px){
        padding:0 30px;
    }
`
const HeroCaptionRight = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    @media (max-width:991.98px){
        background-color:#fff;
        box-shadow:inset 0px 10px 20px -10px rgba(0,0,0,0.3);
    }
    @media (min-width:992px){
        max-width:50%;
        flex:0 0 50%;        
    }
    @media (min-width:1200px){
        padding:0 30px;
    }
    @media (min-width:1680px){
        max-width:41.666667%;
        flex:0 0 41.666667%;
    }
`
const CardHanging = styled.div`
    position: relative;
    z-index: 1;
    margin:0 auto;
    &:after, &:before{
        content:'';
        position:absolute;
        top:0;
        width:2px;
        background: transparent linear-gradient(89deg, #666666 0%, #BCBCBC 100%) 0% 0% no-repeat padding-box;
        z-index:1;
        height:25%;
        @media (min-width:1600px){
            height:32%;
        }
    }
   &:after{
       left:30px;
        @media (min-width:1440px){
            left:50px;
        }
        @media (min-width:1600px){
            left:60px;
        }
    }
    &:before{
        right:30px;
        @media (min-width:1440px){
            right:50px;
        }
        @media (min-width:1600px){
            right:60px;
        }
   }
`
const CardHangingInner = styled.div`
    &:before{		
        background-image:${props => props.bgBeforeImg};
    }
    &:after{		
        background-image:${props => props.bgAfterImg};
    }
    &:after, &:before{
        content: '';
        position: absolute;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: contain;
        top: 25%;
        width: 20px;
        height: 20px;
        @media (min-width:1600px){
            top: 32%;
            width: 28px;
            height: 28px;
        }
   }
   &:after{
       left:21px;
        @media (min-width:1600px){
            left:42px;
        }
        @media (min-width:1600px){
            left:48px;
        }
    }
    &:before{
        right:21px;
        @media (min-width:1600px){
            right:42px;
        }
       @media (min-width:1600px){
           right:48px;
       }
   }
`

const CardHangingItem = styled.div`
    position:relative;
    &.card-hang-item{
        padding-bottom:20px;
        @media (min-width:992px){
            padding-bottom:10px;
        }
        @media (min-width:1440px){
            padding-bottom:20px;
        }
        + .card-hang-item{
            &:after, &:before{
                content:'';
                position:absolute;
                bottom:100%;
                width:2px;
                background:#666;
                height:20px;
                @media (min-width:992px){
                    height:10px;
                }
                @media (min-width:1440px){
                    height:20px;
                }
            }
            &:after{
                left:30px;
                @media (min-width:1600px){
                    left:60px;
                }
            }
            &:before{
                right:30px;
                @media (min-width:1600px){
                    right:60px;
                }
            }
        }
    }
`

const CardDefault = styled.div`
    background-color:#fff;
    border-radius:20px;
    text-align:center;
    box-shadow:0 0 60px rgba(0,0,0,0.4);
   
`
const CardDefaultBody = styled.div`
    padding:20px 40px;
    @media (min-width:992px){
        padding:20px;
    }
    @media (min-width:1440px){
      padding:20px 40px;
    }
    @media (min-width:1600px){
        padding:20px 50px;
    }
    > p {
        font-size:18px;
        line-height:30px;
        margin-bottom:0;
    }

`
const CardDefaultTitle = styled.strong`
    display:block;
    font-weight:700;
    
    font-size:20px;
    line-height:28px; 
    @media(min-width:1200px){
        font-size:22px;
        line-height:30px; 
    }
    @media(min-width:1600px){
        font-size:24px;
        line-height:40px;
    }
`
const CardDefaultLead = styled.div`
    display:block;
    margin:0 auto;
    text-align:center;
    padding:20px 55px 0 55px;
    @media (min-width:1440px){
        padding:40px 65px 0 65px;
    }
    @media (min-width:1600px){
        padding:90px 75px 15px 75px;
    }
    > p{
        font-weight:700;
        color:#000;        

        font-size:20px;
        line-height:32px; 
        @media(min-width:1440px){
            font-size:22px;
            line-height:40px; 
        }
    }
`
const CardForm = styled.div`
    border-radius: 20px;
    max-width:1090px;
    width:100%;
    margin:0 auto;
    position: relative;
    box-shadow:0 0 99px rgba(0,0,0,0.2);
    overflow:hidden;
    &:after{
        content: '';
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:50%;
        background: transparent linear-gradient(182deg, #F8FDFE 0%, #F8FDFE00 100%) 0% 0% no-repeat padding-box;
    }
   
`
const CardFormBody = styled.div`
    position:relative;
    z-index: 1;
    padding:30px 30px 100px 30px;
    @media (min-width:576px){
        padding:40px 60px 180px 60px;
    }
    @media (min-width:768px){
        padding:40px 100px 180px 100px;
    }
    @media (min-width:1200px){
        padding:80px 205px 280px 205px;
    }
    .form{
        .form-action{
            justify-content:center;
        }
        .form-group textarea{
            min-height:140px;
        }
    }
`
const CardFormTitle = styled.h2`
    text-transform:uppercase;
    text-align:center;
    margin-bottom:20px;

`



const DrawingPermits = ({ location, data }) => (
  <Layout location={location}>
    <Seo
        title={data.allContentfulPageData.edges[0].node.metaTitle}
        description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
    />
    <SectionHeros className="section-hero">
        <HeroBanner>
            <SectionImageBkg opacity="0.20"><StaticImage src="../images/drawing-hero.jpg" alt="drawing-hero" /></SectionImageBkg>
            <HeroCaption>
                <div className="container">		
                    <HeroCaptionGrid>
                        <HeroCaptionLeft>
                            <div>
                                <SectionPageTitle className="h2" mb="0" fontWeight="700">DRAWINGS & PERMITS </SectionPageTitle>
                                <SectionDescription fontStyle="italic">
                                    <p>All of our structures include standard generic drawings, upon request. Check with your local building permit office to determine the requirements for installing an outdoor building on your lot of land. Our generic drawings are engineered to meet the general wind needs for our coverage areas. Just note that some permitting offices require “Site Specific” drawings. Please inquire for more information on site-specific drawings</p>
                                </SectionDescription>
                            </div>
                        </HeroCaptionLeft>
                        <HeroCaptionRight>
                            <CardHanging>
                                <CardHangingInner bgBeforeImg={`url(${PosterCircle})`} bgAfterImg={`url(${PosterCircle})`}>
                                    <CardDefaultLead>
                                        <p>TIME SPENT CHECKING LOCAL BUILDING CODES IS WELL WORTH THE UPFRONT INVESTMENT!</p>
                                    </CardDefaultLead>
                                    <CardHangingItem className="card-hang-item">
                                        <CardDefault>
                                            <CardDefaultBody>
                                                <CardDefaultTitle>City/Township</CardDefaultTitle>
                                                <p>Nearly every city and township has its own building codes to ensure that new structures are built to meet local conditions.</p>
                                            </CardDefaultBody>
                                        </CardDefault>
                                    </CardHangingItem>
                                    <CardHangingItem className="card-hang-item">
                                        <CardDefault>
                                            <CardDefaultBody>
                                                <CardDefaultTitle>County</CardDefaultTitle>
                                                <p>If you are located outside of a particular city or township, then you’re likely to fall under the jurisdiction of your county building codes. </p>
                                            </CardDefaultBody>
                                        </CardDefault>
                                    </CardHangingItem>
                                    <CardHangingItem className="card-hang-item">
                                        <CardDefault>
                                            <CardDefaultBody>
                                                <CardDefaultTitle>HOA </CardDefaultTitle>
                                                <p>Check with your homeowner’s association to consider their particular requirements & restrictions.</p>
                                            </CardDefaultBody>
                                        </CardDefault>
                                    </CardHangingItem>
                                </CardHangingInner>
                            </CardHanging>
                        </HeroCaptionRight>
                    </HeroCaptionGrid>
                </div>
            </HeroCaption>
        </HeroBanner>
        <HeroSearch>
            <div className="container"><SearchBar /></div>
        </HeroSearch>
    </SectionHeros>

    <Section pt="80px" pb="50px" xpt="60px" mpt="40px" bgColor="#fff" className="section-drawing-form">
        <div className="container">
             <SectionDescription maxWidth="1090px">
                <p>Our metal structures typically rely on our generic engineered drawings. These drawings are based on a certain set of requirements, and they cover all of those parameters well. If your building falls outside of these pre-defined parameters, then it may require some site-specific plans or wet seal/stamp drawings. Your local city/township and county will be able to clarify what is required of you for your metal building installation. </p>
                <p>You are responsible for handling any permitting requirements, determining whether the structure to be installed meets local code, and providing a level surface area for building installation. Customers who don’t handle these items properly will likely be required to pay extra fees for materials, labor, and other unforeseen expenses. </p>
            </SectionDescription>
            <CardForm>
                <SectionImageBkg height="50%" top="50%">
                    <StaticImage src="../images/contact-bkg.jpg" alt="contact background" />
                </SectionImageBkg>
                <CardFormBody>
                    <CardFormTitle className="h4">Fill the form to Contact Us!!</CardFormTitle>
                    <ContactForm location={location} />
                </CardFormBody>
            </CardForm>
        </div>
    </Section>
  </Layout>
)

export default DrawingPermits;

export const pageQuery = graphql`
  query DrawingPermitPageQuery{
    allContentfulPageData(filter: {page: {eq: "Drawing and Permits"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
  }
`
